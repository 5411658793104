import { Item } from './../interfaces/items.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  loader = true;
  items: Item[] = [];

  constructor( private http: HttpClient) { 
    this.loadItems();
  }

  private loadItems() {
    this.http.get('https://jose-paulino-portfolio.firebaseio.com/items_idx.json')
    .subscribe( (resp: Item[] ) => { 
      this.items = resp;
      this.loader = false;
    });
  }

  getItem( id: string ) {
    return this.http.get(`https://jose-paulino-portfolio.firebaseio.com/items/${ id }.json`);
  }
}
