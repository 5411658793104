import { InfoPage } from './../interfaces/info-page.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InfoPageService {

  info: InfoPage = {};
  isLoaded = false;

  constructor(private http: HttpClient ) { 
    console.log('Service Ready!');
    this.http.get('assets/data/data-page.json')
        .subscribe( 
          (resp: InfoPage) => {
            this.isLoaded = true;
            this.info = resp;
            console.log(resp)
          }
        )
  }
}
