import { Component, OnInit } from '@angular/core';
import { InfoPageService } from './../../services/info-page.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor ( public _service: InfoPageService ) { }

  ngOnInit() {
  }

}
