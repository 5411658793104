import { ItemsService } from './services/items.service';
import { InfoPageService } from './services/info-page.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Jose Paulino - Portfolio';
  
  constructor ( public InfoPageService: InfoPageService,
                public ItemsService: ItemsService
    ) {
    
  }}
