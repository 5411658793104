import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemsService } from 'src/app/services/items.service';
import { ItemDescription } from 'src/app/interfaces/item-description.interface';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  item: ItemDescription;
  id: string;

  constructor( private route: ActivatedRoute,
               public itemService: ItemsService) { }

  ngOnInit() {

    this.route.params
        .subscribe( parametros => {
          this.itemService.getItem(parametros['id'])
            .subscribe( (item: ItemDescription) => {
              this.item = item;
              this.id = parametros['id'];
            })
        })
  }

}
